<template>
  <div class="checkout">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="cart-wrapper">
            <h4>
              Membership Checkout
            </h4>
            <circular-loader
              v-if="isMembershipLoader == true"
            ></circular-loader>
            <div class="cart-item" v-else>
              <div class="row">
                <div class="col-md-10 _content">
                  <div>
                    <h5>{{ membershipTitle }}</h5>
                  </div>
                </div>
                <div class="col-md-2">
                  <h3>₹{{ afterOffer }}</h3>
                  <h6
                    style="color: #f67f29"
                    v-if="membershipOffer > 0 || membershipPrice > 0"
                  >
                    <s>₹{{ membershipPrice }}</s>
                  </h6>
                </div>
              </div>
            </div>

            <h4>
              Billing Address
            </h4>

            <form @submit.prevent="checkout">
              <div class="row">
                <div class="col-md-6 form-group">
                  <div class="mb-3">
                    <label for="firstName" class="form-label">First Name</label>
                    <input
                      type="text"
                      v-model="firstName"
                      class="form-control"
                      id="firstName"
                      aria-describedby=""
                    />
                    <div
                      class="input-errors"
                      v-for="(error, errorIndex) of v$.firstName.$errors"
                      :key="errorIndex"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                    <div v-if="errors && errors.first_name" class="error-msg">
                      {{ errors.first_name[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div class="mb-3">
                    <label for="lastName" class="form-label">Last name</label>
                    <input
                      type="text"
                      v-model="lastName"
                      class="form-control"
                      id="lastName"
                      aria-describedby=""
                    />
                    <div
                      class="input-errors"
                      v-for="(error, errorIndex) of v$.lastName.$errors"
                      :key="errorIndex"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                    <div v-if="errors && errors.last_name" class="error-msg">
                      {{ errors.last_name[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div class="mb-3">
                    <label for="email" class="form-label">Email</label>
                    <input
                      type="text"
                      v-model="email"
                      class="form-control"
                      id="email"
                      aria-describedby=""
                    />
                    <div
                      class="input-errors"
                      v-for="(error, errorIndex) of v$.email.$errors"
                      :key="errorIndex"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                    <div v-if="errors && errors.email" class="error-msg">
                      {{ errors.email[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div class="mb-3">
                    <label for="gst_number" class="form-label"
                      >GST Number</label
                    >
                    <input
                      type="text"
                      v-model="gstNumber"
                      class="form-control"
                      id="gst_number"
                      aria-describedby=""
                    />
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div class="mb-3">
                    <label for="country" class="form-label">Country</label>
                    <input
                      type="text"
                      v-model="country"
                      class="form-control"
                      id="country"
                      aria-describedby=""
                    />
                    <div
                      class="input-errors"
                      v-for="(error, errorIndex) of v$.country.$errors"
                      :key="errorIndex"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                    <div v-if="errors && errors.country" class="error-msg">
                      {{ errors.country[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div class="mb-3">
                    <label for="state" class="form-label">State</label>
                    <input
                      type="text"
                      v-model="state"
                      class="form-control"
                      id="state"
                      aria-describedby=""
                    />
                    <div
                      class="input-errors"
                      v-for="(error, errorIndex) of v$.state.$errors"
                      :key="errorIndex"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                    <div v-if="errors && errors.state" class="error-msg">
                      {{ errors.state[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 form-group">
                  <div class="mb-3">
                    <label for="zip_code" class="form-label">Zip Code</label>
                    <input
                      type="text"
                      v-model="zipCode"
                      class="form-control"
                      id="zip_code"
                      aria-describedby=""
                    />
                    <div
                      class="input-errors"
                      v-for="(error, errorIndex) of v$.zipCode.$errors"
                      :key="errorIndex"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                    <div v-if="errors && errors.zip_code" class="error-msg">
                      {{ errors.zip_code[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-md-12 form-group">
                  <div class="mb-3">
                    <label for="address" class="form-label">Address</label>
                    <textarea
                      class="form-control"
                      v-model="address"
                      id="address"
                    ></textarea>
                    <div
                      class="input-errors"
                      v-for="(error, errorIndex) of v$.address.$errors"
                      :key="errorIndex"
                    >
                      <div class="error-msg">{{ error.$message }}</div>
                    </div>
                    <div v-if="errors && errors.address" class="error-msg">
                      {{ errors.address[0] }}
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                v-if="membershipStatusCount == 0"
                :disabled="isPaymentProgressing == true"
                class="btn cta-primary"
              >
                <span>
                  Check Out
                </span>
                <span v-if="isPaymentProgressing == true">
                  <circular-loader></circular-loader>
                </span>
              </button>
              <span v-if="membershipStatusCount > 0"
                >You are already a member
              </span>
            </form>
          </div>
        </div>
        <div class="col-md-4">
          <div class="summary-wrap">
            <div class="_header">
              <h4>Order summary</h4>
              <circular-loader
                v-if="isMembershipLoader == true"
              ></circular-loader>
              <ul class="list-unstyled" v-else>
                <li>
                  <p>Membership Price</p>
                  <p>₹ {{ roundedPrice }}</p>
                </li>
                <!-- <li v-if="membershipOffer > 0">
                  <p>Discount ({{ membershipOffer }})%</p>
                  <p>-₹ {{ discount }}</p>
                </li> -->
                <li v-if="promoCodePercentage > 0">
                  <p>Promo Code ({{ promoCodePercentage }})%</p>
                  <p>-₹ {{ promoCodeDiscount }}</p>
                </li>
                <li>
                  <p>Tax ({{ taxPercentage }})%</p>
                  <p>₹ {{ taxAmount }}</p>
                </li>
                <li>
                  <p>Total</p>
                  <h5>₹ {{ total }}</h5>
                </li>
              </ul>
            </div>
            <div class="_footer">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="promoCode"
                  placeholder="Have any promocode?"
                />
                <button
                  class="btn btn-outline-secondary"
                  @click="applyPromoCode"
                  type="button"
                  id="button-addon2"
                  :disabled="isLoadingPromoCode == true"
                >
                  Apply
                </button>
                <div v-if="errors && errors.promo_code" class="error-msg">
                  {{ errors.promo_code[0] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MembershipService from "@/services/MembershipService";
import CheckoutService from "@/services/CheckoutService.js";
import CircularLoader from "@/components/loaders/CircularLoader";
import errorLog from "@/errorLog";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
  name: "MembershipCheckout",
  components: {
    CircularLoader
  },
  setup() {
    return {
      v$: useVuelidate()
    };
  },
  data() {
    return {
      membershipPrice: 0,
      membershipOffer: 0,
      membershipTitle: "",
      membershipType: this.$route.query.type,
      isMembershipLoader: true,
      firstName: "",
      lastName: "",
      email: "",
      gstNumber: "",
      country: "",
      state: "",
      zipCode: "",
      address: "",
      billingAddressId: "",
      isPaymentProgressing: false,
      taxPercentage: 0,
      errors: {},
      promoCode: "",
      promoCodePercentage: 0,
      isLoadingPromoCode: false,
      membershipStatusCount: 0
    };
  },
  validations() {
    return {
      firstName: {
        required: helpers.withMessage("Please enter your first name", required)
      },
      lastName: {
        required: helpers.withMessage("Please enter your last name", required)
      },
      email: {
        required: helpers.withMessage("Please enter  your email", required)
      },
      address: {
        required: helpers.withMessage("Please enter your address", required)
      },
      country: {
        required: helpers.withMessage("Please enter your country", required)
      },
      state: {
        required: helpers.withMessage("Please enter your country", required)
      },
      zipCode: {
        required: helpers.withMessage("Please enter your zi code", required)
      }
    };
  },
  created() {
    this.checkMembershipStatus();
    this.getTaxSettings();
    this.getMembershipDetails();
    this.getBillingAddress();
  },
  computed: {
    roundedPrice() {
      return (
        parseFloat(this.afterOffer) -
        (parseFloat(this.afterOffer) * parseFloat(this.taxPercentage)) /
          (100 + parseFloat(this.taxPercentage))
      ).toFixed(2);
    },
    afterOffer() {
      let reduction = this.membershipPrice * (this.membershipOffer / 100);
      return parseFloat(this.membershipPrice) - parseFloat(reduction);
    },
    discount() {
      let reduction = this.membershipPrice * (this.membershipOffer / 100);
      return reduction.toFixed(2);
    },
    taxableAmount: function() {
      let promoCodeDiscount = 0;
      if (this.promoCodePercentage > 0) {
        promoCodeDiscount =
          (parseFloat(this.roundedPrice) *
            parseFloat(this.promoCodePercentage)) /
          100;
      }
      return (
        parseFloat(this.roundedPrice) - parseFloat(promoCodeDiscount)
      ).toFixed(2);
    },
    promoCodeDiscount() {
      return (
        (parseFloat(this.roundedPrice) * parseFloat(this.promoCodePercentage)) /
        100
      ).toFixed(2);
    },
    taxAmount() {
      return (
        (parseFloat(this.taxableAmount) * parseFloat(this.taxPercentage)) /
        100
      ).toFixed(2);
    },
    total() {
      return (
        parseFloat(this.taxableAmount) + parseFloat(this.taxAmount)
      ).toFixed(2);
    },
    user() {
      let user = JSON.parse(this.$store.getters["studentAuth/user"]);
      return user;
    }
  },
  methods: {
    async checkMembershipStatus() {
      await MembershipService.checkMembershipStatus()
        .then(result => {
          this.membershipStatusCount = result.data.count;
        })
        .catch(error => {
          this.isClassLoader = false;
          errorLog.log(error);
        });
    },
    async getMembershipDetails() {
      this.isMembershipLoader = true;
      this.membershipType = this.$route.query.type;
      await MembershipService.getMembershipDetail()
        .then(result => {
          this.isMembershipLoader = false;
          if (this.membershipType == "club") {
            this.membership = result.data.club_membership;
            this.membershipPrice = result.data.club_membership.price;
            this.membershipOffer = result.data.club_membership.offer;
            this.membershipTitle = "Club Membership";
          } else if (this.membershipType == "premium") {
            this.membership = result.data.premium_membership;
            this.membershipPrice = result.data.premium_membership.price;
            this.membershipOffer = result.data.premium_membership.offer;
            this.membershipTitle = "Premium Membership";
          }
          if (this.membershipPrice == null) {
            this.membershipPrice = 0;
          }
        })
        .catch(error => {
          this.isMembershipLoader = false;
          errorLog.log(error);
        });
    },
    async getBillingAddress() {
      await CheckoutService.getBillingAddress()
        .then(result => {
          let billingAddress = result.data;
          if (billingAddress.length == 0) {
            this.firstName = this.user.first_name;
            this.lastName = this.user.last_name;
            this.email = this.user.email;
          } else {
            this.firstName = billingAddress.first_name;
            this.lastName = billingAddress.last_name;
            this.email = billingAddress.email;
            this.address = billingAddress.address;
            this.country = billingAddress.country;
            this.state = billingAddress.state;
            this.zipCode = billingAddress.zip_code;
            this.gstNumber = billingAddress.gst_number;
            this.billingAddressId = billingAddress.id;
          }
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    async getTaxSettings() {
      await CheckoutService.getTaxSettings()
        .then(result => {
          this.taxPercentage = result.data.tax;
        })
        .catch(error => {
          errorLog.log(error);
        });
    },
    checkout: function() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      this.isPaymentProgressing = true;
      const data = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        address: this.address,
        country: this.country,
        state: this.state,
        zip_code: this.zipCode,
        gst_number: this.gstNumber,
        id: this.billingAddressId
      };
      CheckoutService.saveBillingAddress(data)
        .then(result => {
          if (result.data.status == "success") {
            this.payment();
          } else if (result.data.status == "validation_error") {
            this.errors = result.data.message;
          }
        })
        .catch(error => {
          this.isPaymentProgressing = false;
          errorLog.log(error);
        });
    },
    payment() {
      let total = (parseFloat(this.total) * 100).toFixed(2);
      this.isPaymentProgressing = false;
      var rzp1 = new window.Razorpay({
        key: process.env.VUE_APP_RAZOR_PAY_KEY,
        amount: total,
        name: "Skill School",
        currency: "INR",
        description: "test description",
        image: "../assets/Ologylogo-color.png",
        handler: response => {
          this.isPaymentProgressing = true;
          this.saveOrder(response);
          this.$toast.warning("Please wait... Do not refresh page");
        },
        prefill: {
          name: "Athul",
          email: "rkathul@gmail.com",
          contact: "9656823477"
        },
        notes: {
          address: ""
        },
        theme: {
          color: "#00ffff"
        }
      });
      rzp1.open();
      rzp1.on("payment.failed", function(response) {
        alert(response.error.description);
      });
    },
    async saveOrder(response) {
      await CheckoutService.saveMembership({
        response: response.razorpay_payment_id,
        membershipType: this.membershipType,
        promo_code_offer: this.promoCodePercentage,
        promo_code: this.promoCode,
        total: this.total
      })
        .then(result => {
          this.isPaymentProgressing = false;
          if (result.data.status == "success") {
            this.$router
              .push({
                name: "membershipSuccess",
                params: {
                  id: result.data.id
                },
                query: {
                  source: this.membershipType
                }
              })
              .catch(() => {});
          }
        })
        .catch(error => {
          this.isPaymentProgressing = false;
          this.error = error;
          // this.$toastr.e("Something went wrong");
        });
    },
    async applyPromoCode() {
      const data = {
        promo_code: this.promoCode
      };
      this.isLoadingPromoCode = true;
      await CheckoutService.applyPromoCode(data)
        .then(result => {
          this.isLoadingPromoCode = false;
          if (result.data.status == "success") {
            this.promoCodePercentage = result.data.offer;
            this.errors = {};
          } else if (result.data.status == "validation_error") {
            this.errors = result.data.message;
          } else if (result.data.status == "error") {
            this.errors = result.data.message;
          }
        })
        .catch(error => {
          this.isLoadingPromoCode = false;
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/checkout.scss";
</style>

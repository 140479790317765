import Http from "@/services/Http";
export default {
  checkMembershipStatus() {
    return Http().post("membership/status");
  },
  getFaqs(data) {
    return Http().post("/faqs", data);
  },
  getMembershipContent() {
    return Http().post("/membership/content");
  },
  getMembershipDetail() {
    return Http().post("/membership/details");
  },
  getPurchaseDetail(data) {
    return Http().post("/purchase/membership/details", data);
  }
};
